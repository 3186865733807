/**actions required for user list api */

import { createAction, props } from '@ngrx/store';
import {
  OrganisationCreateAPIRequest,
  OrganisationDetailNew,
  OrganisationListAPIResponse,
  organisationCreateAPIResponse,
} from '@models/organisations.model';
import { SortAndPaginationModal, dynamicObject } from '@models/common.model';

export const loadOrganisations = createAction(
  '[API] Load Organisations',
  props<{
    search?: string | '';
    pagination: SortAndPaginationModal;
  }>()
);
export const loadOrganisationSuccess = createAction(
  '[API] Load Organisations Success',
  props<{ organisations: OrganisationListAPIResponse }>()
);
export const loadOrganisationError = createAction(
  '[API] Load Organisations Error',
  props<{ error: any }>()
);

//search
export const loadOrganisationsWithSearch = createAction(
  '[API] Load Site With Search',
  props<{
    search?: string;
    pagination: SortAndPaginationModal;
    searchFilterObj?: dynamicObject;
  }>()
);
export const loadOrganisationsWithSearchSuccess = createAction(
  '[API] Load Sites With Search Success',
  props<{ sites: OrganisationListAPIResponse }>()
);

//create site
export const addOrganisation = createAction(
  '[API] Add Organisation',
  props<{
    organisation: OrganisationCreateAPIRequest;
  }>()
);

export const organisationOperationMessage = createAction(
  '[API] Organisation Operation Message',
  props<{ message: string }>()
);

export const addOrganisationError = createAction(
  '[API] Add Organisation Error',
  props<{ error: any }>()
);

//get organisation detail
export const getOrganisation = createAction(
  '[API] Get Organisation',
  props<{
    id: string | number;
  }>()
);

export const getOrganisationError = createAction(
  '[API] Get Organisation Error',
  props<{ error: any }>()
);

export const getOrganisationSuccess = createAction(
  '[API] Get Organisation Success',
  props<{ organisation: OrganisationDetailNew | null }>()
);

//edit organisation details
export const updateOrganisation = createAction(
  '[API] Update Organisation',
  props<{
    id: string | number;
    organisation: OrganisationCreateAPIRequest;
  }>()
);

export const updateOrganisationError = createAction(
  '[API] Update Organisation Error',
  props<{ error: any }>()
);

export const updateOrganisationSuccess = createAction(
  '[API] Update Organisation Success',
  props<{ organisation: OrganisationCreateAPIRequest }>()
);

//delete organisation
export const deleteOrganisation = createAction(
  '[API] Delete Organisation',
  props<{
    id: string | number;
  }>()
);

export const deleteOrganisationError = createAction(
  '[API] Delete Organisation Error',
  props<{ error: any }>()
);

export const deleteorganisationSuccess = createAction(
  '[API] Delete Organisation Success',
  props<{ site: organisationCreateAPIResponse }>()
);

export const resetStateAfterOperation = createAction(
  '[API] Reset State After Operation'
);

export const resetStateSuccess = createAction('[API] Reset State Success');

export const OrganisationAction = {
  resetStateAfterOperation,
  resetStateSuccess,
  loadOrganisations,
  addOrganisation,
  addOrganisationError,
  deleteOrganisation,
  deleteorganisationSuccess,
  deleteOrganisationError,
  getOrganisationSuccess,
  getOrganisation,
  loadOrganisationError,
  loadOrganisationSuccess,
  updateOrganisation,
  updateOrganisationSuccess,
  loadOrganisationsWithSearch,
};
